import React from "react";
import imgQuadril1 from "../../../assets/produtos_orteses_quadril_1.jpg"
import imgQuadril2 from "../../../assets/produtos_orteses_quadril_2.jpg"
import imgQuadril3 from "../../../assets/produtos_orteses_quadril_3.jpg"

function Pes() {
    return (
        <>
            <h2>Pés</h2>
            <h3>Molded Ancle Foot Ortosis (MAFO) e Palmilhas</h3>
            <p>
                MAFO Articulado (com limitação de flexão plantar em 90°);<br />
                Mantém a Flexão plantar limitada a 90° (evita o padrão equino);<br />
                Permite a Flexão dorsal (não atrapalha na deambulação); Contém a Inversão e Eversão;<br />
                É utilizado com Tênis (não necessita de calçados especiais);<br />
                Confeccionado sob medida;
            </p>
            <p>
                Material utilizado:<br />
                Polipropileno e softform Fixação com velcro
            </p>
        </>
    )
}

export default Pes;