import React from "react";
import abotec from "../assets/abotec.png";
import ispo from "../assets/ISPO.png";
import promocao from "../assets/home_cadeira.jpeg";
import './cards.css';

function Cards() {
  return (
    <div className="cards cards--three-column">
      <div className="card card--podologia">
        <h3 className="card__title">Podologia</h3>
        <p className="card__text card__text--dark">Os cuidados que seus pés merecem</p>
      </div>
      <div className="card card--promocao">
        <h3 className="card__title">Promoção de próteses</h3>
        <p className="card__text card__text--dark">Confira as promoções entrando em contato</p>
      </div>
      <div className="card card--entrega">
        <h3 className="card__title">COVID-19</h3>
        <p className="card__text card__text--dark">
          Nesses dias de pandemia devido ao Corona Vírus, estamos trabalhando em nossas lojas com horário reduzido,
          de 9:00hs às 17:00hs de segunda a sexta e aos sábados de 9:00hs às 13:00hs, atendendo UM cliente por vez.
          <br /><br />
          <b>FAÇA O SEU PEDIDO POR TELEFONE OU WHATSAPP</b> e entregamos em sua residência!
          <br /><br />
          Os pedidos podem ser pagos por link que enviaremos pelo WhatsApp, sem contados com as maquininhas ou
          dinheiro! Tudo para a segurança de todos!
        </p>
      </div>
      <div className="card card--ispo">
        <h3 className="card__title">ISPO e ABOTEC</h3>
        <p className="card__text card__text--dark">
          São José Ortopédicos é uma empresa associada a ABOTEC e ISPO
        </p>
        <img className="card__image" src={abotec} alt="ABOTEC" />
        <img className="card__image" src={ispo} alt="ISPO" />
      </div>
    </div>
  );
}

export default Cards;