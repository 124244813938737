import React from "react";
import facebook from "../assets/faceico.ico";
import twitter from "../assets/twitter.ico";
import {NavLink} from "react-router-dom";
import './footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__container">
        <nav className="footer__map">
          <h3 className="footer__title">Mapa do site</h3>
          <ul className="map-nav">
            <li className="map-nav__item">
              <NavLink className="map-nav__item-link" to="/" exact>Início</NavLink>
            </li>
            <li className="map-nav__item">
              <NavLink className="map-nav__item-link" to="/quem-somos" exact>Quem somos</NavLink>
            </li>
            <li className="map-nav__item">
              <NavLink className="map-nav__item-link" to="/produtos" exact>Produtos</NavLink>
            </li>
            <li className="map-nav__item">
              <NavLink className="map-nav__item-link" to="/servicos" exact>Serviços</NavLink>
            </li>
            <li className="map-nav__item">
              <NavLink className="map-nav__item-link" to="/contato">Contato</NavLink>
            </li>
          </ul>
        </nav>
        <div className="footer__contacts">
          <h3 className="footer__title">Fale conosco</h3>
          <p className="footer__contacts-item">atendimento@saojoseortopedicos.com.br</p>
          <p className="footer__contacts-item">Nova Friburgo: (22) 2523-1535</p>
          <p className="footer__contacts-item">Cabo Frio: (22) 2644-1397</p>
          <p className="footer__contacts-item">Teresópolis: (21) 2742-9932</p>
        </div>
        <div className="footer__social">
          <h3 className="footer__title">Nos acompanhe nas redes sociais</h3>
          <img className="footer__social-image" src={facebook} alt="Facebook"/>
          <img className="footer__social-image" src={twitter} alt="Twitter"/>
        </div>
      </div>
      <div className="footer__copyright">
        ₢ 2022 São José Ortopédicos
      </div>
    </footer>
  );
}

export default Footer;